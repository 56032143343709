import React from "react"

import { withFlexible } from "./withFlexible"
import { Sections } from "../Sections/Sections"
import { HeroBanner } from "../Sections/HeroBanner/HeroBanner"

interface Props {
  content: any
  hero: any
}

export const Flexible = withFlexible(({ content, hero }: Props) => (
  <>
    {hero && <HeroBanner section={hero} condensed={`true`} />}
    <Sections content={content.sections} />
  </>
))
